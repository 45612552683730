//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";

@import "~animate.css";

@media screen and (min-width: 768px){
    @import "partials/768up";
}

@media screen and (max-width: 767px){
    @import "partials/767dwn";
}
@import "partials/form";
@font-face {
    font-family: 'passion-one';
    src: url('../fonts/PassionOne-Regular.eot');
    src: url('../fonts/PassionOne-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PassionOne-Regular.woff2') format('woff2'),
        url('../fonts/PassionOne-Regular.woff') format('woff'),
        url('../fonts/PassionOne-Regular.ttf') format('truetype'),
        url('../fonts/PassionOne-Regular.svg#passionone') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'passion-one-bold';
    src: url('../fonts/PassionOne-Bold.eot');
    src: url('../fonts/PassionOne-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PassionOne-Bold.woff2') format('woff2'),
        url('../fonts/PassionOne-Bold.woff') format('woff'),
        url('../fonts/PassionOne-Bold.ttf') format('truetype'),
        url('../fonts/PassionOne-Bold.svg#passionone-bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'passion-one-black';
    src: url('../fonts/PassionOne-Bold.eot');
    src: url('../fonts/PassionOne-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PassionOne-Bold.woff2') format('woff2'),
        url('../fonts/PassionOne-Bold.woff') format('woff'),
        url('../fonts/PassionOne-Bold.ttf') format('truetype'),
        url('../fonts/PassionOne-Bold.svg#passionone-bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'spartan';
    src: url('../fonts/LeagueSpartan.eot');
    src: url('../fonts/LeagueSpartan.eot?#iefix') format('embedded-opentype'),
        url('../fonts/LeagueSpartan.woff2') format('woff2'),
        url('../fonts/LeagueSpartan.woff') format('woff'),
        url('../fonts/LeagueSpartan.ttf') format('truetype'),
        url('../fonts/LeagueSpartan.svg#spartan') format('svg');
    font-weight: normal;
    font-style: normal;
}


// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

html{
    font-size: 21px;
}
body {
    font-family: 'spartan';
    color: $body-color;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    min-width: 320px;
    .container{
        padding-left: 15px;
        padding-right: 15px;
    }
}

h2, h3, h4, h5, h6{
    line-height: 1;
    font-weight: 700;
    font-variation-settings: "wght" 700;
}
h1, .h1{
    line-height: 1;
    font-family: 'passion-one' !important;
    font-size: 2rem;
}
h2{
    font-size: 1.25rem;
}
.spartan{
    font-family: 'spartan' !important;
}
.purple{
    color: $purple;
}
.magenta{
    color: $magenta;
}
.teal{
    color: $teal;
}
.container{
    max-width: 1440px !important;
}
strong{
    font-weight: 700;
    font-variation-settings: "wght" 700;
}
ul.plain{
    list-style-type: none;
}

.cap-1200{
    max-width: 1200px !important;
}
.cap-1000{
    max-width: 1000px !important;
}
.bg-light-purple{
    background: $light-purple;
}
.bg-light-magenta{
    background: $light-magenta-purple;
}

.no-horz-pad{
    padding-left: 0;
    padding-right: 0;
    > .row{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

ul{
    margin-top: 8px;
    li{
        margin-bottom: 8px;
    }
}

.px-15{
    padding-left: 15px;
    padding-right: 15px;
}

.btn{
    background: $purple;
    color: #fff !important;
    border-radius: 9px;
    text-transform: uppercase;
    display: inline-block;
    font-family: 'spartan';
    font-weight: 700;
    font-variation-settings: "wght" 700;
    font-size: 1.2rem;
    min-width: 240px;
    padding: 14px 30px 7px 30px;
    line-height: 1.1;
    transition: all 0.25s ease-in-out;
    border: 3px solid $purple !important;
    &.big{
        padding: 19px 40px 12px 40px;
    }
    &:hover, &:focus{
        background: darken($purple, 10%);
    }
    &.inverted{
        color: $purple !important;
        background: #fff !important;
        &:hover, &:focus{
            background: $purple !important;
            color: #fff !important;
        }
    }
}
.modal-dialog{
    max-width: 850px !important;
}

.modal-content{
    border-radius: 34px;
    border: 2px solid $purple;
    padding: 20px;
    min-width: 320px;
    .modal-body{
        .btn{
           word-wrap: break-word; 
        }
    }
    .btn-close{
        position: absolute;
        width: 35px;
        height: 35px;
        padding: 4px;
        right: 30px;
        top: 10px;
        border-radius: 50%;
        background: none !important;
        border: 2px solid #fff !important;
        transition: all 0.25s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        &:hover, &:focus{
          border-color: $purple !important;
        }
        svg{
            width: 80%;
            height: auto;
        }
    }
}

/** HEADER **/
#header{
    position: relative;
    z-index: 2;
    &:after{
        content: '';
        position: absolute;
        width: 100%;
        min-width: 1000px;
        background: url('../images/lightteal_wave.svg') no-repeat top right;
        min-height: 165px; //200
        background-size: cover;
        z-index: 5;
        bottom: -78px; //-100
        left: 0;
    }
    .row{
        .img-wrapper{
            padding: 30px 40px 0;
        }
        > div{
            &:last-of-type{
                background: $light-teal;
                position: relative;
                overflow: hidden;
                > img{
                    position: relative;
                    z-index: 5;
                }
                span.wave{
                    position: absolute;
                    width: 100vw;
                    height: 80%;
                    bottom: -88px;
                    right: 0px;
                    min-width: 320px;
                    &:after, &:before{content: ''; position: absolute; background-size: cover !important;width: 100%;height: 75%;}
                    &:after{
                        background: url('../images/purple_wave.svg') no-repeat bottom right;
                        z-index: 3;
                        bottom: 0;
                        right: 0px;
                    }
                    &:before{
                        background: url('../images/teal_wave.svg') no-repeat top right;
                        z-index: 2;
                        bottom: 40%;
                        right: 0;
                    }
                }
                
            }
        }
    }
}

#intro{
    position: relative;
    z-index: 3;
    min-height: 500px;
    background: $light-teal-wave;
    
    > .container{
        position: relative;
        z-index: 2;
    }
}

#why-oic{
    color: #fff;
    //background: $purple;
    background: url('../images/purple_wave2.svg') no-repeat top center;
    background-size: 130% !important;
    position: relative;
    margin-top: 30px;
    > .container{
        max-width: 1150px !important;
    }
    > div{
        position: relative;
        z-index: 2;
    }
    .img-wrapper{
        margin-bottom: 30px;
    }
    .copy{
        max-width: 320px;
        margin: 0 auto;
    }
    &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 90%;
        bottom: 0;
        left: 0;
        z-index: 1;
        background: $purple;
    }
    @media screen and (min-width: 768px){
        margin-top: 60px;
        .img-wrapper{
            min-height: 275px;
        }
        &:before{
            height: 40%;
        }
        //background-size: cover !important;
    }
}

.facts-wrapper{
    .fact{
        border-radius: 13px;
        border: 3px solid $purple;
        padding: 20px;
        margin-bottom: 20px;
    }
    @media screen and (min-width: 768px){
        .fact{
            padding: 36px 40px 34px 40px;
            margin-bottom: 28px;
        }
    }
}

.icon-row{
    position: relative;
    border-radius: 10px;
    padding: 40px 40px 40px 160px;
    min-height: 140px;
    display: flex;
    align-items: center;
    > img{
        position: absolute;
        left: 40px;
        width: 100px;
        height: auto;
        top: 50%;
        margin-top: -50px;
    }

    @media screen and (max-width: 767px){
        padding: 140px 30px 30px;
       > img{
            left: 50%;
            top: 25px;
            margin-top: 0;
            margin-left: -50px;
       }
    }
}

#resources{
    .resource{
        margin-bottom: 20px;
        > a, > div.link-style{
            display: flex;
            padding: 30px 20px;
            border-radius: 30px;
            border: 3px solid $purple;
            background: #fff;
            text-decoration: none;
            height: 100%;
            transition: all 0.4s ease-in-out;
            span.icon{
                display: inline-block;
                width: 100px;
                height: 101px;
                background: url('../images/sprite.png') no-repeat center center;
            }
        }
        &.teal > a{
            border-color: $teal-border;
            color: $teal;
            &:hover, &:focus{
                background: $teal-border;
                color: #fff;
                span.icon{
                    background-position: -2px -105px;
                }
            }
            span.icon{
                background-position: -2px -2px;
            }
        }
        &.magenta > a{
            border-color: $magenta;
            color: $magenta;
            &:hover, &:focus{
                background: $magenta;
                color: #fff;
                span.icon{
                    background-position: -208px -106px;
                }
            }
            span.icon{
                background-position: -208px -3px;
            }
        }

        &.purple{
            .link-style{
                &:hover{
                    background: $purple;
                    color: #fff;
                    .btn{
                        border-color: #fff !important;
                    }
                    span.icon{
                        background-position: -105px -105px;
                    }
                }

                span.icon{
                    background-position: -105px -2px;
                }
            }
        }

        span{
            font-family: 'spartan';
            font-weight: 700;
            font-variation-settings: "wght" 700;
            font-size: 1.2rem;
            &:not(.icon){
                margin-top: 20px;
            }
            line-height: 1.1;
        }
    }
}

#pre-footer{
    background: $light-teal-wave;
}

#footer{
    font-size: 0.8rem;
    color: #52514F;
    &:before{
        content: '';
        position: absolute;
        top: -96px;
        left: 0;
        width: 100%;
        height: 200px;
        background-size: cover !important;
        z-index: 1;
        background: url('../images/white_wave.svg') no-repeat top center;

        @media screen and (min-width: 1500px){
            top: -120px;
        }
    }
    > div{
        position: relative;
        z-index: 2;
    }
    .footer-copy{
        p{
            margin: 0;
        }
    }
    .footer-nav{
        a{
            text-decoration: none;
            color: #52514F;
            &:hover, &:focus{
                text-decoration: underline;
            }
        }
    }
}

@media screen and (min-width: 890px){
    #header:after{
        width: 110% !important;
        bottom: -110px !important;
    }
}

@media screen and (min-width: 1200px){
    #header .row > div:last-of-type > img{
        margin-left: 60px;
    }
}

@media screen and (min-width: 768px){
    #header .row > div:last-of-type{
        span.wave{
            bottom: -88px !important;
            right: -10px !important;
        }
    }
}

@media screen and (min-width: 1331px){
    #header{
        .row > div:last-of-type{
            span.wave{
                bottom: -15% !important;
                &:before{bottom: 30%;}
                &:before, &:after{
                    background-size: contain !important;
                }
            }
        }
        &:after{
            bottom: -102px !important;
        }
    }
}


@media screen and (min-width: 1500px){
    #header{
        &:after{
            min-height: 220px !important;
            bottom: -60px !important;
        }
    }
}
@media screen and (min-width: 1700px){
  #why-oic{
    background-size: 110% !important;
  }
}

.footer-logo-wrapper{
    @media screen and (min-width: 768px) {
        > img{
            &:not(:first-of-type){
                margin-left: 10px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        margin-bottom: 0 !important;
        > img{
           display: block;
           margin: 0 auto 15px;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 960px) {
        align-items: center !important;
        .us_pain{
            width: 45%;
        }
    }
}