.form-wrapper{
    width: 100%;
    input[type="text"], textarea{
        border-radius: 9px;
        border: 1px solid #52514F;
    }
    textarea{
        padding: 20px 15px;
    }
    .field-input{
        margin-bottom: 20px;
    }
    span.error{
        display: block;
        margin-top: 4px;
        color: red;
        font-style: italic;
        padding-left: 4px;
    }

    .styled-checkbox{
        input[type="checkbox"]{
            position: absolute;
            opacity: 0;
            &:checked + label:after{
                background: $magenta;
            }
        }
        label{
            position: relative;
            padding-left: 40px;
            margin-bottom: 0 !important;
            cursor: pointer;
            &:before, &:after{
                content: '';
                position: absolute;
            }
            &:before{
                width: 25px;
                height: 25px;
                border: 1px solid #52514F;
                left: 0;
                top: 0px;
                z-index: 1;
            }
            &:after{
                width: 17px;
                height: 17px;
                background: #fff;
                left: 4px;
                top: 4px;
                z-index: 2;
            }
        }
    }
}
button.btn{
    &.loading{
        background: $purple !important;
        color: #fff !important;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &:after{
            content: '';
            margin-left: 5px;
            margin-top: -4px;
            width: 25px;
            height: 25px;
            display: inline-block;
            background: url('../images/spinner.gif') no-repeat center center;
            background-size: contain;
        }
    }
}