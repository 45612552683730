
#main-content{
    h2{
        font-size: 1.4rem;
    }
    h1, .h1{
        font-size: 2.28rem !important;
    }
}


.no-horz-pad-md{
    padding-left: 0;
    padding-right: 0;
}

.capped-md-500{
    max-width: 500px;
}

#header{
    .row .img-wrapper{padding-left: 70px;}
    &:after{
        min-height: 200px !important;
        bottom: -128px !important;
    }
}

.col2, .col3{
    display: flex;
    justify-content: space-between;
    &.center{
        justify-content: center;
        margin-left: -12px;
        margin-right: -12px;
        > div{
            margin-left: 12px;
            margin-right: 12px;
        }
    }
}

.col2{
    > div{
        width: calc(50% - 12px);
    }
}

.col3{
    > div{
        width: calc(33.3333333% - 12px);
    }
}

#pre-footer{
    padding-bottom: 150px !important;
}

#footer{
    .row{
        > div{
            &:first-of-type{
                width: 30%;
            }
            &:last-of-type{
                width: 70%;
            }
        }
    }
    .footer-nav{
        > li{
            margin-left: 25px;
        }
    }
}